var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            loading: _vm.loading.submit,
            visible: _vm.uploadDialog.visible,
            title: _vm.uploadDialog.title,
            "submit-title": "提交",
            "cancel-title": "关闭",
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.uploadDialog, "visible", $event)
            },
            submit: _vm.confirmUpdate,
            close: _vm.close
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                model: _vm.formInfo,
                rules: _vm.rules,
                "label-width": "140px",
                "label-position": "left",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "upload-inner",
                  staticStyle: {
                    width: "90%",
                    top: "50%",
                    left: "50%",
                    "margin-left": "30px",
                    "margin-top": "10px"
                  }
                },
                [
                  _c(
                    "fero-upload",
                    {
                      ref: "upload",
                      attrs: {
                        limit: _vm.limit,
                        headers: _vm.headers,
                        action: _vm.uploadUrl,
                        drag: "",
                        accept: ".jpg,.png,.jpeg,.pdf",
                        "auto-upload": false,
                        "before-upload": _vm.beforeUploadFile,
                        "on-success": _vm.onSuccessFile,
                        "on-preview": _vm.previewFile,
                        "on-error": _vm.onErrorFile,
                        "on-change": _vm.onChange,
                        "on-remove": _vm.onChange,
                        "on-exceed": _vm.onExceed
                      },
                      model: {
                        value: _vm.formInfo.fileList,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "fileList", $$v)
                        },
                        expression: "formInfo.fileList"
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")])
                      ]),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 上传文件只能是 jpg,png,jpeg,pdf格式 ")
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }